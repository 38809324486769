@import "./global.css";

/* 🔹 Universal Styling for Subsections */
.subsection-container {
  max-width: 1100px;
  margin: auto;
  padding: 60px 50px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

/* 🔹 Main Section Title */
.subsection-title {
  font-size: 42px;
  font-weight: 700;
  color: #222;
  text-align: left;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 4px solid var(--tiffany-blue);
  font-family: "Montserrat", sans-serif;
  opacity: 0;
  animation: fadeInTitle 0.6s ease-in-out forwards;
}

/* 🔹 Section Styling */
section {
  padding: 40px;
  border-radius: 6px;
  margin-bottom: 30px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* 🔹 Alternate Backgrounds for Sections & Answered Questions */
section:nth-child(odd), 
.qa-answer-item:nth-child(odd) {
  background: #f8f9fa;
}

section:nth-child(even), 
.qa-answer-item:nth-child(even) {
  background: #ffffff;
}

/* 🔹 Interactive Floating Animation (Desktop Only) */
@media (min-width: 1025px) {
  section:hover,
  .qa-answer-item:hover {
    transform: translateY(-6px);
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
}

/* 🔹 Section Headers */
section h2 {
  font-size: 34px;
  font-weight: 600;
  color: var(--tiffany-blue);
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* 🔹 Paragraph Styling */
section p {
  font-size: 20px;
  line-height: 1.7;
  color: #333;
  margin-bottom: 15px;
}

/* 🔹 Bullet Points */
section ul {
  list-style-type: disc;
  padding-left: 25px;
}

section ul li {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

/* 🔹 Subtle Fade-In Animation */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

section {
  animation: fadeIn 0.6s ease-in-out;
}

/* 🔹 Smooth fade-in effect for subsection titles */
@keyframes fadeInTitle {
  from {
      opacity: 0;
      transform: translateY(10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

/* 🔹 Responsive Tweaks */
@media (max-width: 1024px) {
  .subsection-container {
      max-width: 90%;
      padding: 40px 25px;
  }

  .subsection-title {
      font-size: 32px;
  }

  section h2 {
      font-size: 26px;
  }

  section p, section ul li {
      font-size: 16px;
  }
}

/* ✅ Center CTA Button */
.cta-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

/* ✅ Call-to-Action Button */
.cta-button {
  display: inline-block;
  padding: 14px 28px;
  background: var(--tiffany-blue);
  color: var(--off-white);
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 10px;
  transition: background 0.3s ease, transform 0.2s ease;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}

/* ✅ Button Hover Effect */
.cta-button:hover {
  background: var(--bright-coral);
  transform: scale(1.05);
}

/* ✅ Remove Extra Box from CTA */
.cta-section {
  background: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

/* ✅ Q&A Submission Page Enhancements */
.qa-submission-info {
  font-size: 18px;
  color: #444;
  text-align: center;
  margin-bottom: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* ✅ Submission Form */
.qa-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* ✅ Submission Box Styling */
.qa-textarea {
  width: 100%;
  max-width: 700px;
  height: 120px;
  padding: 12px;
  border: 2px solid var(--slate-gray);
  border-radius: 6px;
  font-size: 16px;
  color: var(--dark-gray);
  background: white;
  resize: none;
}

/* ✅ Centered Submit Button */
.qa-submit-button {
  margin-top: 20px;
  padding: 14px 28px;
  background: var(--tiffany-blue);
  color: var(--off-white);
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  transition: background 0.3s ease, transform 0.2s ease;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  text-align: center;
}

.qa-submit-button:hover {
  background: var(--bright-coral);
  transform: scale(1.05);
}

/* ✅ Answered Questions Formatting (Now Floating Like Other Subsections) */
.qa-answer-item {
  background: #ffffff;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 20px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* ✅ Floating Animation on Hover (Desktop) */
@media (min-width: 1025px) {
  .qa-answer-item:hover {
    transform: translateY(-6px);
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1);
  }
}

/* ✅ Question Styling */
.question-text {
  font-size: 22px;
  font-weight: bold;
  color: var(--tiffany-blue);
  margin-bottom: 10px;
}

/* ✅ Answer Styling */
.answer-text {
  font-size: 18px;
  font-style: italic;
  color: var(--dark-gray);
  line-height: 1.6;
}

/* ✅ Mobile Optimization */
@media (max-width: 768px) {
  .qa-submission-info {
    font-size: 16px;
  }

  .qa-textarea {
    font-size: 14px;
    padding: 10px;
    height: 100px;
  }

  .qa-submit-button {
    font-size: 18px;
    padding: 12px 22px;
  }

  .question-text {
    font-size: 20px;
  }

  .answer-text {
    font-size: 16px;
  }
}
/* ✅ Top Questions Styling */
.question-item {
  background: #ffffff; /* Default White */
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 20px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* ✅ Alternating Background Colors (White & Gray) */
.question-item:nth-child(odd) {
  background: #f8f9fa; /* Light Gray */
}

.question-item:nth-child(even) {
  background: #ffffff; /* White */
}

/* ✅ Floating Animation on Desktop */
@media (min-width: 1025px) {
  .question-item:hover {
    transform: translateY(-4px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.08);
  }
}

/* ✅ Question Text - Now Matches Other Sections */
.question-text {
  font-size: 20px;
  color: var(--dark-gray);
  line-height: 1.6;
}

/* ✅ Voting Container */
.vote-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

/* ✅ Vote Count */
.vote-count {
  font-size: 18px;
  font-weight: bold;
  color: var(--slate-gray);
}

/* ✅ Vote Buttons */
.vote-button {
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease;
}

/* ✅ Disabled State */
.vote-button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* ✅ Upvote Icon (Green) */
.up-icon {
  font-size: 24px;
  color: #27ae60; /* Green */
}

.vote-button.upvote:hover .up-icon {
  transform: scale(1.2);
}

/* ✅ Downvote Icon (Red) */
.down-icon {
  font-size: 24px;
  color: #e74c3c; /* Red */
}

.vote-button.downvote:hover .down-icon {
  transform: scale(1.2);
}

/* ✅ Mobile Optimization */
@media (max-width: 768px) {
  .subsection-container {
    max-width: 95%;
    padding: 40px 20px;
    text-align: left;
  }

  .subsection-title {
    font-size: 32px;
    text-align: left;
  }

  section {
    padding: 30px 20px;
  }

  section h2 {
    font-size: 24px;
    text-align: left;
  }

  section p, 
  section ul li {
    font-size: 16px;
    text-align: left;
  }
  .subsection-container {
    padding: 30px 15px;  /* Reduce side padding to make text fill more */
  }

  section p, 
  section ul li {
    max-width: 100%; /* Make sure text uses full width of its container */
    text-align: left;  /* Keep alignment natural */
  }

  .question-item {
    padding: 15px;
  }

  .question-text {
    font-size: 18px;
  }

  .vote-buttons {
    gap: 8px;
  }

  .vote-button .up-icon,
  .vote-button .down-icon {
    font-size: 20px;
  }

  .vote-count {
    font-size: 16px;
  }
}
.calculator-button {
  display: inline-block;
  padding: 14px 28px;
  background: var(--tiffany-blue);
  color: var(--off-white);
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 10px;
  transition: background 0.3s ease, transform 0.2s ease;
  text-align: center;
  cursor: pointer;
  border: none;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}

.calculator-button:hover {
  background: var(--bright-coral);
  transform: scale(1.05);
}
.calculator-button-container {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  margin-top: 10px; /* Optional spacing */
}
.calculator-disclaimer-container {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
}
/* 🔹 Disclaimer Section Styling */
.disclaimer-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding: 2rem;
  background-color: #f8f9fa; /* Light gray background to match the example */
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.disclaimer-container {
  max-width: 600px;
  width: 100%;
}

.disclaimer-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0ABAB5; /* Matches the color scheme */
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.disclaimer-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.disclaimer-button {
  background-color: #0ABAB5; /* Matches primary button color */
  color: #fff;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  border: none;
  cursor: pointer;
}

.disclaimer-button:hover {
  background-color: #088f90; /* Darker teal for hover effect */
}

.disclaimer-text-container {
  margin-top: 0.5rem;
}

.disclaimer-text {
  font-size: 0.9rem;
  color: #555;
  font-style: italic;
}

.disclaimer-text a {
  color: #0ABAB5; /* Link color */
  text-decoration: none;
  font-weight: 600;
}

.disclaimer-text a:hover {
  text-decoration: underline;
}
/* Existing styles for qa-textarea (if any) */
.qa-textarea {
  width: 100%; /* Example existing style */
  padding: 10px; /* Example existing style */
  font-size: 16px; /* Example existing style */
  /* Add your desired font for the textarea content */
  font-family: 'Arial', sans-serif; /* Default font for typed text */
}

/* Style the placeholder specifically */
.qa-textarea::placeholder {
  font-family: 'Roboto', sans-serif; /* Change to your desired font */
  color: #888; /* Optional: Adjust placeholder color */
  font-style: italic; /* Optional: Add italic style */
}
/* Ensure the subsection-container has relative positioning to contain the absolute back arrow */
.subsection-container {
  position: relative; /* Allows absolute positioning of children like back-arrow */
  padding: 2rem; /* Adjust if your actual padding differs */
}

/* Style for the back arrow in the upper left corner */
.back-arrow {
  position: absolute;
  top: 0.5rem; /* Aligns vertically with the title */
  left: 1rem; /* Matches typical spacing in your layout */
  font-size: 1.5rem; /* Size to match the prominence of other elements */
  color: #00C4B4; /* Matches the teal color of the Submit Question button and title underline */
  background-color: transparent; /* No background to keep it minimal */
  border: none; /* No border to keep it clean */
  padding: 0.5rem; /* Small padding to make the clickable area larger */
  text-decoration: none; /* Remove underline from the Link */
  transition: color 0.3s ease; /* Smooth transition for color change on hover */
  display: inline-flex; /* Ensures proper alignment of the icon */
  align-items: center; /* Vertically centers the icon */
  justify-content: center; /* Horizontally centers the icon */
}

/* Hover effect to match the Submit Question button */
.back-arrow:hover {
  color: #00A89A; /* Slightly darker teal for hover effect */
} 
/* 🔹 Section Image Styling */
.section-image {
  display: block; /* Ensures the image is a block element for centering */
  max-width: 100%; /* Makes the image responsive, never exceeding its container */
  height: auto; /* Maintains aspect ratio */
  margin: 20px auto; /* Centers the image with vertical spacing */
  border-radius: 6px; /* Matches the rounded corners of your sections */
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  animation: fadeIn 0.6s ease-in-out; /* Reuses your fade-in animation */
}

/* 🔹 Responsive Adjustments for Images */
@media (max-width: 1024px) {
  .section-image {
    max-width: 90%; /* Slightly reduce width on tablets for better fit */
    margin: 15px auto; /* Reduce vertical spacing */
  }
}

@media (max-width: 768px) {
  .section-image {
    max-width: 100%; /* Full width on mobile for better visibility */
    margin: 10px auto; /* Further reduce vertical spacing */
  }
}